export const passwordRegEx = /^(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\W]{6,25}$/

export function hasValidLengthOnPassword(password: string): boolean {
  return password.length > 5 && password.length < 26
}

export function hasUppercaseCharOnPassword(password: string): boolean {
  return !!password.match(/[A-Z]/)
}

export function hasNumberOnPassword(password: string): boolean {
  return !!password.match(/[0-9]/)
}

export function getPasswordValidations() {
  return [["matches", passwordRegEx], ["required"]]
}
