<script setup lang="ts">
import {
  hasNumberOnPassword,
  hasUppercaseCharOnPassword,
  hasValidLengthOnPassword
} from "~/utils/input/password"
import { PasswordCheckProps } from "./PasswordCheck.props"

const props = withDefaults(defineProps<PasswordCheckProps>(), {
  theme: "site"
})

const passwordFieldRef = ref(props.passwordField)
const hasValidPassword = computed(() =>
  hasValidLengthOnPassword(passwordFieldRef.value)
)
const hasUppercaseChar = computed(() =>
  hasUppercaseCharOnPassword(passwordFieldRef.value)
)
const hasNumber = computed(() => hasNumberOnPassword(passwordFieldRef.value))

watch(
  () => props.passwordField,
  (newValue) => {
    passwordFieldRef.value = newValue
  }
)
</script>

<template>
  <div class="mouse flex flex-col gap-1">
    <span>{{ $t("textfields.passwordCheck.passwordRule") }}</span>

    <TextBlocksListItemWithCheck
      :show-checkmark="hasValidPassword"
      :info-message="$t('textfields.passwordCheck.passwordLenght')"
    ></TextBlocksListItemWithCheck>

    <TextBlocksListItemWithCheck
      :show-checkmark="hasUppercaseChar"
      :info-message="$t('textfields.passwordCheck.passwordUppercase')"
    ></TextBlocksListItemWithCheck>

    <TextBlocksListItemWithCheck
      :show-checkmark="hasNumber"
      :info-message="$t('textfields.passwordCheck.passwordNumber')"
    ></TextBlocksListItemWithCheck>
  </div>
</template>
